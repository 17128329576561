<!--
 * @Author: your name
 * @Date: 2021-12-15 11:39:48
 * @LastEditTime: 2021-12-27 11:05:07
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\car\page1.vue
-->
<template>
  <div class="xj101-page1 pt80">
    <div class="title-div">
      <d-title model="ST-XJ101" ch="智能巡检无人车"></d-title>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/xj101/1.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
import dTitle from "@/components/sTitle/title";
export default {
  name: "",
  components: {
    dTitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.xj101-page1 {
  .img-div {
    width: 100%;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .xj101-page1 {
    overflow: hidden;
    width: 100%;
    .img-div {
      display: flex;
      justify-content: center;
      overflow: hidden;
      margin-top: 0.36rem;
      img {
        width: 150%;
      }
    }
  }
}
</style>
