<!--
 * @Author: your name
 * @Date: 2021-12-15 13:22:39
 * @LastEditTime: 2021-12-24 11:30:06
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\xj101\page4.vue
-->
<template>
  <div class="xj101-page4 pt80">
    <div class="title-div">
      <span class="label-s">灵活选装，多场景应用</span>
      <p class="p-24">
        提供多种作业模式与多种交互方式，匹配不同应用场景，选择适合你的作业模式
      </p>
    </div>
    <ul class="msg-ul">
      <li>安防</li>
      <li>科研</li>
      <li>勘探</li>
      <li>无人巡检</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.xj101-page4 {
  height: 100vh;
  text-align: center;
  position: relative;
  background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/xj101/3.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .label-s {
    font-size: 60px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    display: block;
    margin-bottom: 10px;
  }

  .msg-ul {
    text-align: left;
    position: absolute;
    top: 240px;
    left: 15vw;
    font-size: 90px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: $color-active;
    line-height: 120px;
  }
}
@media screen and (max-height: 798px) {
  .xj101-page4 {
    .msg-ul {
      font-size: 60px;
      line-height: 80px;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .xj101-page4 {
    height: 11rem;
    background-position: 74% center;

    .label-s {
      font-size: 0.55rem;
    }
    .p-24 {
      width: 86%;
      margin: auto;
      text-align: center;
    }

    .msg-ul {
      text-align: left;
      position: relative;
      top: 0.27rem;
      left: 0;
      font-size: 0.73rem;
      line-height: 1.09rem;
      text-align: center;
    }
  }
}
</style>
