<!--
 * @Author: your name
 * @Date: 2021-12-15 11:42:49
 * @LastEditTime: 2021-12-24 11:32:26
 * @LastEditors: Please set LastEditors
 * @Description:
 * @FilePath: \new-website-1123\src\views\special\xj101\page2.vue
-->
<template>
  <div class="xj101-page2 pt80">
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/xj101/4.png"
        alt="上太科技"
      />
    </div>

    <div class="img-div img2-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/xj101/4.png"
        alt="上太科技"
      />
    </div>

    <div class="filter-div"></div>

    <div class="text1-div pt80">
      <div class="t-top">
        <span class="label-s">多模式多场景，自由适配</span>
        <p class="desc-p p-24">
          巡防无人车采用模块化、智能化设计理念，具有强大载荷能力和强劲动力系统的它可广泛应用于无人巡检、安防、科研、勘探等。
        </p>
      </div>
      <ul class="msg-ul">
        <li>强大载荷能力</li>
        <li>强劲动力系统</li>
        <li>多负载拓展</li>
        <li>灵活选装，多场景应用</li>
      </ul>
    </div>
    <div class="text2-div pt80">
      <span class="label2-s">强大载荷能力</span>
      <p class="desc-p p-24">自动驾驶，智能往返，巡检载物轻松自如。</p>
      <ul class="n-ul">
        <li>
          <span class="c-s din">50 Kg</span>
          <span>载重量</span>
        </li>
        <li>
          <span class="c-s din">4 H</span>
          <span>续航</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.xj101-page2 {
  position: relative;
  height: 100vh;
  color: #fff;
  text-align: center;
  overflow: hidden;
  .filter-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000, $alpha: 0.7);
    opacity: 0;
    z-index: 1;
  }

  .img-div {
    height: 100%;
    width: 40%;
    // background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/xj101/4.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    &.img2-div {
      opacity: 0;
    }
    img {
      width: 100%;
      // height: 100%;
      object-fit: contain;
      position: absolute;
      bottom: 0;
    }
  }
  .text1-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 2;
    .t-top {
      margin-top: 100px;
      transform: translateY(100%);
      opacity: 0;
      .desc-p {
        opacity: 0;
      }
    }
    .label-s {
      font-size: 90px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: $color-active;
    }
    .msg-ul {
      margin-top: 100px;
      opacity: 0;
      transform: translateY(-20%);
      li {
        font-size: 60px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin-top: 10px;
      }
    }
  }
  .text2-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    .label2-s {
      font-size: 60px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    .n-ul {
      display: flex;
      justify-content: space-between;
      width: 78%;
      margin: auto;
      margin-top: 180px;
      li {
        display: flex;
        flex-direction: column;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba($color: #fff, $alpha: 0.8);
        text-align: left;
        .c-s {
          font-size: 70px;
          font-weight: bold;
          color: $color-active;
        }
      }
    }
  }
  .desc-p {
    width: 1000px;
    margin: auto;
    margin-top: 12px;
  }
}
@media screen and (max-height: 800px) {
  .xj101-page2 {
    .text1-div {
      .t-top {
        margin-top: 60px;
        transform: translateY(100%);
        opacity: 0;
        .desc-p {
          opacity: 0;
        }
      }
      .label-s {
        font-size: 70px;
      }
      .msg-ul {
        margin-top: 80px;
        opacity: 0;
        transform: translateY(-20%);
        li {
          font-size: 48px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          margin-top: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .xj101-page2 {
    height: auto;
    padding-top: 40px;
    .img-div {
      height: auto;
      width: 86%;
      img {
        width: 100%;
        position: relative;
      }
      &.img2-div {
        opacity: 1;
        width: 100%;
      }
    }

    .text1-div {
      .t-top {
        margin-top: 0;
        transform: translateY(0);
        opacity: 1;
        .desc-p {
          opacity: 1;
          width: 86%;
        }
      }
      .label-s {
        font-size: 0.73rem;
        width: 80%;
        display: block;
        margin: auto;
      }
      .msg-ul {
        margin-top: 2.1rem;
        opacity: 1;
        transform: translateY(-20%);
        li {
          font-size: 0.55rem;
          margin-top: 10px;
        }
      }
    }
    .text2-div {
      position: relative;
      opacity: 1;
      background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/xj101/4.png");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      padding: 0.91rem;
      .label2-s {
        font-size: 0.55rem;
      }
      .n-ul {
        display: flex;
        justify-content: space-between;
        width: 86%;
        margin: auto;
        margin-top: 1.1rem;
        li {
          display: flex;
          flex-direction: column;
          font-size: 0.24rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba($color: #fff, $alpha: 0.8);
          text-align: left;
          .c-s {
            font-size: 0.73rem;
            font-weight: bold;
            color: $color-active;
          }
        }
      }
    }
    .desc-p {
      width: 1000px;
      margin: auto;
      margin-top: 12px;
    }
  }
}
</style>
