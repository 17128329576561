<!--
 * @Author: your name
 * @Date: 2021-12-15 13:20:49
 * @LastEditTime: 2021-12-24 11:24:06
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\xj101\page3.vue
-->
<template>
  <div
    class="xj101-page3 pt80"
    :style="{
      'background-image': `url(${url})`,
    }"
  >
    <div class="text-div">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.xj101-page3 {
  height: 100vh;
  padding-top: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  .text-div {
    text-align: center;
    ::v-deep {
      .label-s {
        font-size: 60px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .xj101-page3 {
    height: 5.45rem;
    padding-top: $mobile-header-height;
    .text-div {
      ::v-deep {
        .label-s {
          font-size: 0.55rem;
        }
        .p-24 {
          width: 86%;
          margin: auto;
          text-align: center;
        }
      }
    }
  }
}
</style>
